var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { staticClass: "px-4", attrs: { app: "", color: "accent" } },
        [
          _c(
            "router-link",
            { attrs: { to: { name: "organisationHome" } } },
            [
              _c("v-img", {
                attrs: {
                  alt: "muse - logo",
                  src: require("../assets/logo.png"),
                  transition: "scale-transition",
                  width: "120"
                }
              })
            ],
            1
          ),
          _c("v-spacer"),
          _vm.showOrganisationButton
            ? _c(
                "v-icon",
                {
                  staticClass: "ma-6",
                  attrs: { color: "white" },
                  on: {
                    click: function($event) {
                      return _vm.navigateToOrganisation()
                    }
                  }
                },
                [_vm._v("location_city")]
              )
            : _vm._e(),
          _vm.$auth.isAuthenticated && !this.$route.meta.public
            ? _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$auth.logout(_vm.logoutOptions)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("power_settings_new")
                  ]),
                  _vm._v(" Logout ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("v-main", [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }